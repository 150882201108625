import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { album, albumDetails } from '../../models/albums/album.models';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  albums: album[] | any;
  albumInfo: album | any;


  albInfoUpdated = new Subject();

  imageSlider: Array<albumDetails> | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  async getAlbums() {
    let username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      let albumsEndPoint = environment.apiUrl + '/api/Albums/merchant/' + username;
      this.albums = await this.http.get<album[]>(albumsEndPoint).toPromise();
    }
  }

  getAlbumDetails(albumId: string) {
    const albumsDetailsEndPoint = environment.apiUrl + '/api/Albums/' + albumId;
    this.http.get<album>(albumsDetailsEndPoint).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((data: album | any) => {
      this.albumInfo = data;

      this.albInfoUpdated.next(data);
    });
  }



  async getAlbumDetailsAsync(albumId: string): Promise<album> {
    const albumsDetailsEndPoint = environment.apiUrl + '/api/Albums/' + albumId;
    return this.http.get<album>(albumsDetailsEndPoint).toPromise();
  }
}
