import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { message } from 'src/app/core/models/ROI/message.models';
import { BlogService } from 'src/app/core/services/blogs/blog.service';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  loaderEnabled = true;
  public contactForm: FormGroup;
  constructor(private route: ActivatedRoute,
    public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private themeService: ThemeChangerService,
    public categoryService: CategoryService,
     public blogService: BlogService) { 

        // force route reload whenever params change;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.contactForm = new FormGroup({
        email: new FormControl(null, Validators.required),
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, null),
        telephone: new FormControl(null, Validators.required),
        messageText: new FormControl(null, Validators.required)
      });

     }
     ngOnChanges(): void{
      this.setSEOData();

     }
 async ngOnInit(): Promise<void> {
   const routeParams = this.route.snapshot.paramMap;
   const index = Number(routeParams.get('id'));
   
   await this.blogService.getPosts(0, 3);
   await this.blogService.getPostInfo(index);
   this.categoryService.getCategories();
   this.setSEOData();

   await this.themeService.changeThemeColors();
   this.loaderEnabled = false;
 }

 contactUs():void{
  const {email, firstName, lastName, telephone, messageText} = this.contactForm.value;

  if(firstName == null || firstName == "")
  {
    this.toastr.error("First name is mandatory");
  }
  else if(email == null || email == "")
  {
    this.toastr.error("Email is mandatory");
  }
  else if(telephone == null || telephone == "")
  {
    this.toastr.error("Mobile number is mandatory");
  }
  else if(messageText == null || messageText == "")
  {
    this.toastr.error("Message is mandatory");
  }
  else{

    let usernameDetails = this.cookieService.get("username");
    let messageModel: message = {
      websiteName: usernameDetails,
      contactName: firstName + ',' + lastName,
      email : email,
      from: telephone,
      message : messageText,
      productId :""
    };

    const contactEndpoint = environment.apiUrl + "/api/Internal/messages";
    
    this.http.post(contactEndpoint, messageModel, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if(d == null)
      {
        this.toastr.success("Thank you for contacting us, we will get back to you shortly");
      }
      else if (d.err) {
        this.toastr.error("There is an issue at our end, please contact us at directly by calling us or sending us an email");
      }
    
    });

  }

 
}
 extractContent(html: string) {

  return new DOMParser().parseFromString(html, "text/html") . 
      documentElement . textContent;

}


 setSEOData(): void {
   const merchantDetails = this.merchantService.merchant;
  
   let description = this.extractContent(this.blogService?.Article?.description);

   let aboutDescription = `${this.blogService.Article.title}, ${description}, Blog of ${merchantDetails.name}, Blog of ${merchantDetails.name} in ${merchantDetails.locality}, Blog of  ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

   this.titleService.setTitle(aboutDescription);

   this.metaService.addTag({name: 'name', content: aboutDescription});
   this.metaService.addTag({name: 'description', content: aboutDescription});
   this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
   this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
   this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


   this.metaService.addTag({name: 'twitter:card', content: 'product'});
   this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
   this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
   this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


   //OG GRAPH DATA
   this.metaService.addTag({name: 'og:type', content: 'article'});
   this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
   this.metaService.addTag({name: 'og:url', content: merchantDetails.url + this.blogService.Article.slug});
   this.metaService.addTag({name: 'og:description', content: aboutDescription});
   this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})

   
 }

}
