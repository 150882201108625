import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  favIcon: HTMLLinkElement | any = document.querySelector('#appIcon');
  constructor(private titleService: Title, private merchantService: MerchantService) { }

  ngOnInit(): void {
    this.titleService.setTitle("404 | Not Found");
    this.favIcon.href =  this.merchantService.merchant.favImage ? this.merchantService.merchant.favImage.small: "https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/favicon-big.png";
  }

}
