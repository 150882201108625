import { NgModule } from '@angular/core';
import {CommonModule, KeyValuePipe} from "@angular/common";
import { DateFormatPipe } from './dateFormat';
import { EscapeHtmlPipe } from './keep-html.format';


@NgModule({
  declarations:[DateFormatPipe, EscapeHtmlPipe], 
  imports:[CommonModule],
  exports:[DateFormatPipe, EscapeHtmlPipe] 
})

export class MainPipe{}