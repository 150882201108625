import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlbumService } from 'src/app/core/services/albums/album.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css']
})
export class AlbumsComponent implements OnInit {

  loaderEnabled = true;
  constructor(public merchantService: MerchantService,
    public albumService: AlbumService,
    private metaService: Meta,
    private titleService: Title,
    private themeService: ThemeChangerService,
    private routerService: Router) { 
      this.routerService.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  async ngOnInit(): Promise<void> {
    await this.albumService.getAlbums();
    this.setSEOData();
    await this.themeService.changeThemeColors();
    this.loaderEnabled = false;
  }

  onItemClick(data: any) {
    this.routerService.navigateByUrl(`albums/${data.name}/${data._id}`);
  }


  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `Albums of ${merchantDetails.name}, Albums of ${merchantDetails.name} in ${merchantDetails.locality}, Albums of  ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/albums/1"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
