import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';

import { message } from 'src/app/core/models/ROI/message.models';
import { take, catchError } from 'rxjs/operators';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bizquery',
  templateUrl: './bizquery.component.html',
  styleUrls: ['./bizquery.component.css']
})
export class BizqueryComponent implements OnInit {
  public bizQueryForm: FormGroup;
  constructor(private http: HttpClient, 
    public merchantService: MerchantService, 
    private toastr: ToastrService,
    private cookieService: CookieService) { 

    this.bizQueryForm = new FormGroup({  
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, null),
      email: new FormControl(null, Validators.required),
      telephone: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
  }


  sendBizQuery(){
      const {firstName, lastName, email, telephone, message} = this.bizQueryForm.value;

      if(firstName == null || firstName == "")
      {
        this.toastr.error("First name is mandatory");
      }
      else if(email == null || email == "")
      {
        this.toastr.error("Email is mandatory");
      }
      else if(telephone == null || telephone == "")
      {
        this.toastr.error("Mobile number is mandatory");
      }
      else if(message == null || message == "")
      {
        this.toastr.error("Message is mandatory");
      }
      else{

        let usernameDetails = this.cookieService.get("username");
        let messageModel: message = {
          websiteName: usernameDetails,
          contactName: firstName + ',' + lastName,
          email : email,
          from: telephone,
          message : message,
          productId :""
        };
    
        let contactEndpoint = environment.apiUrl + "/api/Internal/messages";
        
        this.http.post(contactEndpoint, messageModel, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
        }).pipe(
          take(1),
          catchError(err => {
            return of({ err: true, message: err.error.error_description })
          })
        ).subscribe((d: any) => {
          if(d == null)
          {
            this.toastr.success("Thank you for contacting us");
          }
          else if (d.err) {
            this.toastr.error("There is an issue at our end, please contact us at directly by calling us or sending us an email");
            
          }
        
        });

      }
      

     

  }
}
