import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { DomainService } from '../domain/domain.service';
import { JwthelperService } from '../jwt/jwthelper.service';
import { MerchantService } from '../merchant/merchant.service';
import { TestimonialsService } from '../testimony/testimonials.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private jwtHelper: JwthelperService,
    private domainService: DomainService,
    private cookieService: CookieService,
    private merchantService: MerchantService,
    private testimonialService: TestimonialsService,
    private router: Router
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      
      if (!this.jwtHelper.isJwtValid() || !this.cookieService.get('username')) {
        
        let url = window.location.href;
         
        return this.jwtHelper.authenticateObserver(url)
          .pipe(
            tap(res => this.jwtHelper.setToken(res)),
            concatMap(() => this.domainService.getUsernameObserver(url)),
            tap((res: any) => {
              this.cookieService.set('username', res, 3600);
              this.merchantService.setUserName(res);
            }),
            concatMap(() => this.merchantService.getMechangeDetailsObserver()),
            tap((data: any) => {
              this.merchantServiceHelper(data, route)
            })
          );
      }
      else if (!this.merchantService.getMerchantData()) {
  
        return this.merchantService.getMechangeDetailsObserver().pipe(
          tap(data => {
            this.merchantService.setUserName(this.cookieService.get('username'));
            this.merchantServiceHelper(data, route);
          }));
  
      }
      else {
        return of(true);
      }
    }
  
    merchantServiceHelper(data: any, route: ActivatedRouteSnapshot): Observable<any> {
      this.merchantService.setMerchantData(data);
      this.testimonialService.getTestimonails();
      const urlPath: string[] = [];
      let redirectPath = '/';
      if (route.url.length > 0) {
        route.url.forEach((e: any) => urlPath.push(e));
        redirectPath = urlPath.join('/');
      }
      else {
        this.router.navigateByUrl('/');
      }
      this.router.navigateByUrl(redirectPath);
      return of(true);
    }
}
