import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  workingHours: string | any;
  workingDay: string | any;
  favIcon: HTMLLinkElement | any = document.querySelector('#appIcon');
  constructor(private http: HttpClient,
    public merchantService: MerchantService,
    public categoryService: CategoryService) { }

  ngOnInit(): void {
    const windowObj = window as any;
    const jquery = windowObj.$;
    jquery(".meanclose").click(function () {
      const container = jquery("#mobilenavcontainer")
      container.toggleClass("DN");
      if (container.hasClass('DN')) {
        container.hide('slow');
      }
      else {
        container.show('slow')
      }
      const elm = document.getElementById("menufont");
      if (elm?.classList.contains("fa-bars")) {
        elm.classList.remove("fa-bars");
        elm.classList.add("fa-times");
        return;
      }
      if (elm?.classList.contains('fa-times')) {
        elm.classList.remove("fa-times");
        elm.classList.add("fa-bars");
      }
    })

    jquery(".nav-item").click(function (e: any, d: any) {
      let ul = jquery(e.currentTarget).find(".dropdown-menu")
      if (ul && ul.length > 0) {
        ul = jquery(ul);
        (ul).toggleClass("DB")
        if(ul.hasClass("DB")){
          ul.show('slow')
        }
        else{
          ul.hide('slow')
        }
      }
    })

    this.getWorkingHour();
    this.categoryService.getCategories();
    this.favIcon.href = this.merchantService.merchant.favImage ? this.merchantService.merchant.favImage.small : "https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/favicon-big.png";
  }

  getWorkingHour(): void {
    let day = new Date().getDay();
    if (day == 0) {
      this.workingDay = "Sunday";
      this.workingHours = this.merchantService.merchant.bizHours ? this.merchantService.merchant.bizHours.sunday : "Off";
    }
    else if (day == 1) {
      this.workingDay = "Monday";
      this.workingHours = this.merchantService.merchant.bizHours ? this.merchantService.merchant.bizHours.monday : "Off";
    }
    else if (day == 2) {
      this.workingDay = "Tuesday";
      this.workingHours = this.merchantService.merchant.bizHours ? this.merchantService.merchant.bizHours.tuesday : "Off";
    }
    else if (day == 3) {
      this.workingDay = "Wednesday";
      this.workingHours = this.merchantService.merchant.bizHours ? this.merchantService.merchant.bizHours.wednesday : "Off";
    }
    else if (day == 4) {
      this.workingDay = "Thursday";
      this.workingHours = this.merchantService.merchant.bizHours ? this.merchantService.merchant.bizHours.thursday : "Off";
    }
    else if (day == 5) {
      this.workingDay = "Friday";
      this.workingHours = this.merchantService.merchant.bizHours ? this.merchantService.merchant.bizHours.friday : "Off";
    }
    else {
      this.workingDay = "Saturday";
      this.workingHours = this.merchantService.merchant.bizHours ? this.merchantService.merchant.bizHours.saturday : "Off";
    }
  }

}
