import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit {

  loaderEnabled = true;
  categoryName: String | any;
  constructor(private router: Router,
    private route: ActivatedRoute,
    public categoryService: CategoryService,
    public merchantService: MerchantService,
    private metaService: Meta,
    private themeService: ThemeChangerService,
    private titleService: Title

  ) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit(): Promise<void> {
    const routeParams = this.route.snapshot.paramMap;
    const index = String(routeParams.get('index'));
    this.categoryName = String(routeParams.get('name'));
    this.categoryName = this.categoryName.replace('-', ' ');

    await this.categoryService.getProductsForCategory(index);
    this.setSEOData();
    await this.themeService.changeThemeColors();
    this.loaderEnabled = false;

  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `${this.categoryName}, Products of ${merchantDetails.name}, Products of ${merchantDetails.name} in ${merchantDetails.locality}, Products of ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/products/1"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
