<header class="header-area">
  <div class="top-header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8">
          <ul class="header-contact-info">
            <li>
              <i class="far fa-clock"></i>{{ this.workingDay }}
              {{ workingHours }}
            </li>
            <li>
              <i class="fas fa-phone"></i> Call Us:
              <a
                href="tel:{{ merchantService.merchant.countryCode }}{{
                  merchantService.merchant.mobileNumber
                }}"
                title="Call us"
                itemprop="telephone"
                >{{ merchantService?.merchant?.mobileNumber }}</a
              >
            </li>
            <li>
              <i class="far fa-paper-plane"></i>
              <a
                href="mailto:{{ merchantService?.merchant?.email }}"
                title="Email us"
              >
                <span class="__cf_email__" itemprop="email">{{
                  merchantService?.merchant?.email
                }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <div class="header-right-content">
            <ul class="top-header-social">
              <li>
                <a href="#" *ngIf="!merchantService?.merchant?.socialLinks">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  href="{{ merchantService?.merchant?.socialLinks?.fbUrl }}"
                  *ngIf="merchantService.merchant.socialLinks"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" *ngIf="!merchantService.merchant.socialLinks">
                  <i class="fab fa-twitter"></i>
                </a>
                <a
                  href="{{
                    merchantService.merchant.socialLinks.twitterUsername
                  }}"
                  *ngIf="merchantService.merchant.socialLinks"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" *ngIf="!merchantService.merchant.socialLinks">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="{{ merchantService.merchant.socialLinks.linkedIn }}"
                  *ngIf="merchantService.merchant.socialLinks"
                >
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="#" *ngIf="!merchantService.merchant.socialLinks">
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  href="{{ merchantService.merchant.socialLinks.instagram }}"
                  *ngIf="merchantService.merchant.socialLinks"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#" *ngIf="!merchantService.merchant.socialLinks">
                  <i class="fab fa-pinterest"></i>
                </a>
                <a
                  href="{{ merchantService.merchant.socialLinks.pinterest }}"
                  *ngIf="merchantService.merchant.socialLinks"
                >
                  <i class="fab fa-pinterest"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar-area">
    <div class="fovia-responsive-nav">
      <div class="container">
        <div class="fovia-responsive-menu mean-container">
          <div class="mean-bar zindex">
            <a
              href="#nav"
              class="meanmenu-reveal meanclose"
              style="
                right: 0px;
                left: auto;
                text-align: center;
                text-indent: 0px;
                font-size: 18px;
              "
              ><i id="menufont" class="fas fa-bars"></i></a
            >
            <nav class="mean-nav">
              <ul class="navbar-nav DN" id="mobilenavcontainer" style="display: none;">
                <li class="nav-item">
                  <a  routerLink="/"  title="{{merchantService.merchant.name}}" class="nav-link active">Home</a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link"
                    >Pages <i class="fas fa-plus"></i
                  ></a>
                  <ul class="dropdown-menu" >
                    <li class="nav-item">
                      <a title="About us" itemprop="url" routerLink="/about" class="nav-link">About</a>
                    </li>
                    <li class="nav-item">
                      <a title="Customer Testimonials" itemprop="url" routerLink="/testimonials" class="nav-link"
                        >Testimonials</a
                      >
                    </li>
                    <li class="nav-item">
                      <a routerLink="/albums/1" title="Our Gallery" itemprop="url" class="nav-link">Gallery</a>
                    </li>
                  </ul>
                  <a class="mean-expand" href="#" style="font-size: 18px">+</a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link"
                    >Departments <i class="fas fa-plus"></i>
                  </a>
                  <ul class="dropdown-menu" c id="menudropdown" style="visibility: none;">
                    
                    <li class="nav-item" *ngFor="let prodCat of categoryService?.productCategories">
                      <a routerLink="/{{
                        prodCat.slug.replace('category', 'departments/doctors')
                      }}"
                      title="{{ prodCat.name }}"
                      itemprop="url" class="nav-link"
                        >{{ prodCat.name }}</a
                      >
                      <ul
                      class="dropdown-menu"
                      *ngIf="prodCat.childCategories.length > 0">
                      <li class="nav-item" *ngFor="let childCat of prodCat?.childCategories">
                        <a routerLink="/{{
                          childCat.slug.replace('category', 'departments/doctors')
                        }}"
                        title="{{ childCat.name }}"
                        itemprop="url" class="nav-link"
                          >{{ childCat.name }}</a
                        >
                        </li>
                    </ul>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/doctors/1" title="View all doctors" class="nav-link"
                        >View All Doctors</a
                      >
                    </li>
                  </ul>
                  <a class="mean-expand" href="#" style="font-size: 18px">+</a>
                </li>

                <li class="nav-item">
                  <a href="#" class="nav-link"
                    >Services <i class="fas fa-plus"></i>
                  </a>
                  <ul class="dropdown-menu" c id="menudropdown" style="visibility: none;">
                    
                    <li class="nav-item" *ngFor="let prodCat of categoryService?.productCategories">
                      <a routerLink="/{{
                        prodCat.slug.replace('category', 'departments/services')
                      }}"
                      title="{{ prodCat.name }}"
                      itemprop="url" class="nav-link"
                        >{{ prodCat.name }}</a
                      >
                      <ul
                      class="dropdown-menu"
                      *ngIf="prodCat.childCategories.length > 0">
                      <li class="nav-item" *ngFor="let childCat of prodCat?.childCategories">
                        <a routerLink="/{{
                          childCat.slug.replace('category', 'departments/services')
                        }}"
                        title="{{ childCat.name }}"
                        itemprop="url" class="nav-link"
                          >{{ childCat.name }}</a
                        >
                        </li>
                    </ul>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/services/1" itemprop="url" title="View all services" class="nav-link"
                        >View All Services</a
                      >
                    </li>
                  </ul>
                  <a class="mean-expand" href="#" style="font-size: 18px">+</a>
                </li>
                
                <li class="nav-item">
                  <a routerLink="/blog/1" title="Our Blog" itemprop="url" class="nav-link"
                    >Blog </a>
                </li>
                
               
               
                <li class="nav-item mean-last">
                  <a routerLink="/contact" title="Contact us" itemprop="url" class="nav-link">Contact</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="logo">
            <a
              title="{{ merchantService.merchant.name }}"
              itemprop="url"
              routerLink="/"
            >
              <img
                src="{{ merchantService.merchant.logoImage.actual }}"
                alt="{{ merchantService.merchant.name }}"
                style="max-width: 120px; max-height: 60px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="fovia-nav">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <a class="navbar-brand" href="#home" routerLink="/">
            <img
              src="{{ merchantService.merchant.logoImage.actual }}"
              alt="{{ merchantService.merchant.name }}"
              style="max-width: 220px; max-height: 120px"
            />
          </a>
          <div
            class="collapse navbar-collapse mean-menu"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <a href="#home" routerLink="/" class="nav-link">Home</a>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link"
                  >Pages
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a
                      href="#about"
                      title="About us"
                      itemprop="url"
                      routerLink="/about"
                      class="nav-link"
                      >About</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="#about"
                      title="Testimonials"
                      itemprop="url"
                      routerLink="/testimonials"
                      class="nav-link"
                      >Testimonials</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="#about"
                      title="Gallery"
                      itemprop="url"
                      routerLink="/albums/1"
                      class="nav-link"
                      >Gallery</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link"
                  >Departments
                </a>
                <ul class="dropdown-menu">
                  <li
                    class="nav-item"
                    *ngFor="let prodCat of categoryService.productCategories"
                  >
                    <a
                      routerLink="/{{
                        prodCat.slug.replace('category', 'departments/doctors')
                      }}"
                      title="{{ prodCat.name }}"
                      itemprop="url"
                      class="nav-link"
                    >
                      {{ prodCat.name }}
                    </a>
                    <ul
                      class="dropdown-menu"
                      *ngIf="prodCat.childCategories.length > 0"
                    >
                      <li
                        class="nav-item"
                        *ngFor="let childCat of prodCat.childCategories"
                      >
                        <a
                          routerLink="/{{
                            childCat.slug.replace(
                              'category',
                              'departments/doctors'
                            )
                          }}"
                          title="{{ childCat.name }}"
                          itemprop="url"
                          class="nav-link"
                        >
                          {{ childCat.name }}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a
                      title="View All Doctors"
                      itemprop="url"
                      routerLink="/doctors/1"
                      class="nav-link"
                      >View All</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link"
                  >Services
                </a>
                <ul class="dropdown-menu">
                  <li
                    class="nav-item"
                    *ngFor="let prodCat of categoryService.productCategories"
                  >
                    <a
                      routerLink="/{{
                        prodCat.slug.replace('category', 'departments/services')
                      }}"
                      title="{{ prodCat.name }}"
                      itemprop="url"
                      class="nav-link"
                    >
                      {{ prodCat.name }}
                    </a>
                    <ul
                      class="dropdown-menu"
                      *ngIf="prodCat.childCategories.length > 0"
                    >
                      <li
                        class="nav-item"
                        *ngFor="let childCat of prodCat.childCategories"
                      >
                        <a
                          routerLink="/{{
                            childCat.slug.replace(
                              'category',
                              'departments/services'
                            )
                          }}"
                          title="{{ childCat.name }}"
                          itemprop="url"
                          class="nav-link"
                        >
                          {{ childCat.name }}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a
                      title="View All Services"
                      itemprop="url"
                      routerLink="/services/1"
                      class="nav-link"
                      >View All</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog/1"
                  title="Our Blog"
                  itemprop="url"
                  class="nav-link"
                  >Blog</a
                >
              </li>

              <li class="nav-item">
                <a
                  routerLink="/contact"
                  title="Contact us"
                  itemprop="url"
                  class="nav-link"
                  >Contact</a
                >
              </li>
            </ul>
            <div class="others-options">
              <a
                routerLink="/contact"
                title="Contact us"
                itemprop="url"
                class="btn btn-primary"
                >Contact Us</a
              >
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
