import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {

  loaderEnabled = true;
  imageData: ImageSliderModel[] = [];
  private subscription = new Subscription();
  constructor(private route: ActivatedRoute, public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    private themeService: ThemeChangerService,
    public productService: ServiceService, private router: Router) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     }

  async ngOnInit(): Promise<void> {
   
    this.subscription = this.productService.imageDataUpdated.subscribe((d: any) => {
      this.imageData = [...d];

      this.setSEOData();
    })
    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));
    
    await this.productService.getServices(0, 3);
    this.productService.getServiceInfo(index);

    await this.themeService.changeThemeColors();
    this.loaderEnabled = false;
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  extractContent(html: string) {

    return new DOMParser().parseFromString(html, "text/html") . 
        documentElement . textContent;
  
  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   
    let description = this.extractContent(this.productService?.serviceInfo?.description);
    let title = this.productService.serviceInfo.name + " at " + this.merchantService.merchant.name;
    if(this.productService.serviceInfo.shortBio)
    {
      title = title + ", "+ this.productService.serviceInfo.shortBio;
    }

    let aboutDescription = `${title}, ${description}, ${title} in ${merchantDetails.locality},${title} in ${merchantDetails.city}, Services of ${merchantDetails.name}, Services of ${merchantDetails.name} in ${merchantDetails.locality}, Services of ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    let serviceUrl = this.productService.serviceInfo.name.replace(" ", "-");
    serviceUrl = serviceUrl.replace("products", "services");

    const routeParams = this.route.snapshot.paramMap;
    let index = Number(routeParams.get('index'));
    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/services/" + serviceUrl + "/" + index});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
