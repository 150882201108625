<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>
<section class="page-title-area page-title-bg1" style="background-image:none; background-color: #f4f9fd">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="page-title-content">
					<h2 itemprop="name">{{blogService?.Article?.title}}</h2>
					<ul>
						<li>
							<a itemprop="url" title="{{merchantService?.merchant?.name}}" routerlink="/">Home</a>
						</li>
						<li>Blog</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="blog-details-area ptb-100">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 col-md-12" itemscope="" itemtype="http://schema.org/offers">
				<div class="blog-details-desc">
					<div class="article-image">
                        <img *ngIf="!blogService?.Article?.imageModel" itemprop="image" alt="{{blogService?.Article?.title}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/medium/5fff3d958d5dbc10304de1bb.png">
                        <img *ngIf="blogService?.Article?.imageModel" itemprop="image" alt="{{blogService?.Article?.title}}" src="{{blogService?.Article?.imageModel?.medium}}">
					</div>
						<div class="article-content">
							<div class="entry-meta">
								<ul>
									<li>
										<span>Posted On </span>
										<a title="{{blogService?.Article?.title}}" itemprop="url" routerLink="/{{blogService?.Article?.slug}}"> : {{blogService?.Article?.createdOn | dateFormat }}</a>
									</li>
									<li>
										<span>Posted By:</span>
										<a title="{{merchantService?.merchant?.name}}" itemprop="url" routerLink="/">{{merchantService?.merchant?.name}}</a>
									</li>
								</ul>
							</div>
							<h3 itemprop="name">{{blogService?.Article?.title}}</h3>
							    <p [innerHTML]="blogService?.Article?.description | keepHtml">Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
							</div>
                            <div class="article-footer">
                                <div class="article-tags">
                                    <span>
                                        <i class="fas fa-bookmark"></i>
                                    </span>
                                    <a routerLink="/search/{{keyTag?.replaceAll(' ','-')}}/1" title="{{keyTag}}" *ngFor="let keyTag of blogService?.Article?.keywords; let last=last;">
                                        {{keyTag}}<span *ngIf="!last">, </span></a>
                                </div>
                                <div class="article-share">
                                    <ul class="social">
                                        <li>
                                            <span>Share:</span>
                                        </li>
                                        <li>
                                            <a href="http://www.facebook.com/sharer.php?u={{merchantService?.merchant?.url}}/{{blogService?.Article?.slug}}" target="_blank">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/share?url={{merchantService?.merchant?.url}}/{{blogService?.Article?.slug}}" target="_blank">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://pinterest.com/pin/create/bookmarklet/?url={{merchantService?.merchant?.url}}/{{blogService?.Article?.slug}}" target="_blank">
                                                <i class="fab fa-pinterest"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="comments-area">
										
                                <div class="comment-respond">
                                    <h3 class="comment-reply-title">Leave a Reply</h3>
                                    <form class="comment-form" [formGroup]="contactForm" (ngSubmit)="contactUs()"> 
                                        <p class="comment-notes">
                                            <span id="email-notes">Your email address will not be published.</span>Required fields are marked

                                            <span class="required">*</span>
                                        </p>
                                        <p class="comment-form-comment">
                                            <label>Comment
                                                <span class="required">*</span>
                                            </label>
                                            <textarea  formControlName="messageText" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                        </p>
                                        <p class="comment-form-author">
                                            <label>First Name 
                                                <span class="required">*</span>
                                            </label>
                                            <input type="text" formControlName="firstName">
                                        </p>
                                        <p class="comment-form-author">
                                            <label>Last Name 
                                            </label>
                                            <input type="text" formControlName="lastName">
                                        </p>
                                            <p class="comment-form-author">
                                                <label>Email 
                                                    <span class="required">*</span>
                                                </label>
                                                <input type="email" formControlName="email">
                                            </p>
                                            <p class="comment-form-author">
                                                <label>Mobile Number 
                                                    <span class="required">*</span>
                                                </label>
                                                <input type="text" formControlName="telephone">
                                            </p>
                                            <p class="form-submit">
                                                <input type="submit" class="submit" value="Post Comment">
                                                </p>
                                                
                                                    
                                                    
                                    </form>
        </div>
                            </div>
									
									
													</div>
												</div>
																	<div class="col-lg-4 col-md-12">
																		<aside class="widget-area" id="secondary">
																			<section class="widget widget_search">
																				<form class="search-form">
																					<label>
																						<span class="screen-reader-text">Search for:</span>
																						<input type="search" class="search-field" placeholder="Search...">
																						</label>
																						<button type="submit">
																							<i class="fas fa-search"></i>
																						</button>
																					</form>
																				</section>
																				<section class="widget widget_fovia_posts_thumb">
																					<h3 class="widget-title">Popular Posts</h3>
																					<article class="item" *ngFor="let pdItem of blogService.Posts">
																						<a routerLink="/{{pdItem.slug}}" title="{{pdItem.title}}" itemprop="url" class="thumb">
                                                                                            <span itemprop="image" *ngIf="pdItem.imageModel" class="fullimage cover bg1" role="img" style="background-image: url('{{pdItem.imageModel.small}}');"></span>
																							<span itemprop="image" *ngIf="!pdItem.imageModel" class="fullimage cover bg1" role="img" style="background-image: url('https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png');"></span>
																						</a>
																						<div class="info">
																							<time > {{pdItem.createdOn | dateFormat}} </time>
																							<h4 class="title usmall">
																								<a routerLink="/{{pdItem.slug}}" title="{{pdItem.title}}" itemprop="url">{{pdItem.title}}</a>
																							</h4>
																						</div>
																						<div class="clear"></div>
																					</article>
																				</section>
																				
																				<section class="widget widget_categories">
																					<h3 class="widget-title">Categories</h3>
																					<ul>
																						<li *ngFor="let catItem of categoryService.productCategories">
																							<a routerLink="/{{catItem.slug.replace('category','departments/services')}}" title="{{catItem.name}}" itemprop="url">{{catItem.name}}</a>
																						</li>
																					</ul>
																				</section>
																				<section class="widget widget_tag_cloud">
																					<h3 class="widget-title">Tags</h3>
																					<div class="tagcloud">
																						<a href="/search/{{keyItem.replaceAll(' ', '-')}}/1" *ngFor="let keyItem of merchantService.merchant.keywords">{{keyItem}} 
																							<span class="tag-link-count"></span>
																						</a>
																					</div>
																				</section>
																			</aside>
																		</div>
																	</div>
																</div>
															</section>
<app-footer></app-footer>