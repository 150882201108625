import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';
import { TestimonialsService } from 'src/app/core/services/testimony/testimonials.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  loaderEnabled = true;
  constructor(public testimonialService: TestimonialsService, 
    private titleService: Title,
    private metaService: Meta,
    private themeService: ThemeChangerService,
    public merchantService: MerchantService) { }

  async ngOnInit(): Promise<void> {

    this.testimonialService.getTestimonails();
    this.setSEOData();
    await this.themeService.changeThemeColors();
    this.loaderEnabled = false;
  }


  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `Testimonials of ${merchantDetails.name}, Testimonials of ${merchantDetails.name} in ${merchantDetails.locality}, Testimonials of ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/testimonials"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
