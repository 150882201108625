
<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>
<section class="page-title-area page-title-bg4" style="background-image:none; background-color: #f4f9fd">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="page-title-content">
					<h2>Contact</h2>
					<ul>
						<li>
							<a title="{{merchantService.merchant.name}}" itemprop="url" routerLink="/">Home</a>
						</li>
						<li>Contact</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="contact-area ptb-100">
	<div class="container">
		<div class="section-title">
			<span>Send Message</span>
			<h2>Drop us message for any query</h2>
			<p>If you have an idea, we would love to hear about it.</p>
		</div>
		<div class="row">
			<div class="col-lg-7 col-md-12">
				<div class="contact-form">
					<form [formGroup]="contactForm" (ngSubmit)="contactUs()">
						<div class="row">
							<div class="col-lg-6 col-md-6">
								<div class="form-group">
									<input type="text" formControlName="firstName" class="form-control" required data-error="First name" placeholder="First Name*">
										<div class="help-block with-errors"></div>
									</div>
								</div>
								<div class="col-lg-6 col-md-6">
									<div class="form-group">
										<input type="text" formControlName="lastName" class="form-control" required data-error="Last name" placeholder="Last Name">
											<div class="help-block with-errors"></div>
										</div>
									</div>
									<div class="col-lg-6 col-md-6">
										<div class="form-group">
											<input type="email"  formControlName="email"  class="form-control" placeholder="Email*">
												<div class="help-block with-errors"></div>
											</div>
										</div>
										<div class="col-lg-6 col-md-6">
											<div class="form-group">
												<input type="text" formControlName="phone" class="form-control" placeholder="Phone*">
													<div class="help-block with-errors"></div>
												</div>
											</div>
											<div class="col-lg-12 col-md-12">
												<div class="form-group">
													<textarea name="message" class="form-control"  formControlName="message" id="message" cols="30" rows="6" placeholder="Your Message*"></textarea>
													<div class="help-block with-errors"></div>
												</div>
											</div>
											<div class="col-lg-12 col-md-12">
												<button type="submit" class="btn btn-primary">Send Message 
													<i class="flaticon-right-chevron"></i>
												</button>
												<div id="msgSubmit" class="h3 text-center hidden"></div>
												<div class="clearfix"></div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="col-lg-5 col-md-12">
								<div class="contact-info">
									<ul>
										<li>
											<div class="icon">
												<i class="fas fa-map-marker-alt"></i>
											</div>
											<span>Address</span>
    
    {{merchantService.merchant.address}} {{merchantService.merchant.locality}} {{merchantService.merchant.city}} {{merchantService.merchant.country}} {{merchantService.merchant.pincode}}
    
										</li>
										<li>
											<div class="icon">
												<i class="fas fa-envelope"></i>
											</div>
											<span>Email</span>
											<span class="__cf_email__">
												<a href="mailto:{{merchantService.merchant.email}}" title="Email us">{{merchantService.merchant.email}}</a>
											</span>
										</li>
										<li>
											<div class="icon">
												<i class="fas fa-phone-volume"></i>
											</div>
											<span>Phone</span>
											<a href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}" title="Call Us">{{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				
					
					
					</section>


				<agm-map [zoom]="13" [usePanning]="true"  [latitude]="lat" [longitude]="lng" >
					<agm-marker [latitude]="lat" [longitude]="lng">
					  <agm-info-window>Börk</agm-info-window>
					</agm-marker>
				  </agm-map>

					<app-footer></app-footer>