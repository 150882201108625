import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { HeaderComponent } from './Components/header/header.component';
import { FooterComponent } from './Components/footer/footer.component';
import { BlogComponent } from './Components/blog/blog.component';
import { AboutComponent } from './Components/about/about.component';
import { FaqComponent } from './Components/faq/faq.component';
import { ProductsComponent } from './Components/products/products.component';
import { RequestInterceptorService } from './core/services/requestInterceptor/request-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContactComponent } from './Components/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './Components/error/error.component';
import { ServicesComponent } from './Components/services/services.component';
import { ServiceDetailsComponent } from './Components/services/service-details/service-details.component';
import { ServiceCategoriesComponent } from './Components/services/service-categories/service-categories.component';
import { ProductDetailsComponent } from './Components/products/product-details/product-details.component';
import { ProductCategoryComponent } from './Components/products/product-category/product-category.component';
import { AlbumsComponent } from './Components/albums/albums.component';
import { AlbumDetailsComponent } from './Components/albums/album-details/album-details.component';
import { BlogDetailsComponent } from './Components/blog/blog-details/blog-details.component';
import { CarouselComponent } from './Components/carousel/carousel.component';
import { BackgroundComponent } from './Components/home/background/background.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { MainPipe } from './core/helpers/mainPipe.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BizqueryComponent } from './Components/home/bizquery/bizquery.component';
import { CommonModule } from '@angular/common';
import { WhatsappComponent } from './Components/whatsapp/whatsapp.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { ProductCarouselComponent } from './Components/products/product-carousel/product-carousel.component';
import { ServiceCarouselComponent } from './Components/services/service-carousel/service-carousel.component';
import { TestimonialsComponent } from './Components/testimonials/testimonials.component';
import { AlbumCarouseComponent } from './Components/albums/album-carouse/album-carouse.component';
import { PreloaderComponent } from './Components/common/preloader/preloader.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    ContactComponent,
    FooterComponent,
    BlogComponent,
    AboutComponent,
    FaqComponent,
    ProductsComponent,
    ErrorComponent,
    ServicesComponent,
    ServiceDetailsComponent,
    ServiceCategoriesComponent,
    ProductDetailsComponent,
    ProductCategoryComponent,
    AlbumsComponent,
    AlbumDetailsComponent,
    BlogDetailsComponent,
    CarouselComponent,
    BackgroundComponent,
    BizqueryComponent,
    WhatsappComponent,
    ProductCarouselComponent,
    ServiceCarouselComponent,
    TestimonialsComponent,
    AlbumCarouseComponent,
    PreloaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgImageSliderModule,
    MainPipe,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.mapsKey
    })
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
