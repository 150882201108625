<div class="blur">
    <div *ngIf="imageData.length > 0">
      <div *ngIf="!isMobile$; else mobile">
        <div id="aboutus" class="section about-us-area t-center">
          <ng-image-slider
            [images]="imageData"
            [class]="'blurs'"
            [infinite]="true"
            [autoSlide]="1"
            [autoSlide]="{ interval: 4, stopOnHover: true }"
            [imageSize]="{ width: '100%', height: '100%' }"
            #nav
          >
            this is for test
          </ng-image-slider>
        </div>
      </div>
      <ng-template #mobile>
        <div class="section about-us-area t-center">
          <ng-image-slider
            [images]="imageData"
            [infinite]="true"
            [autoSlide]="1"
            [autoSlide]="{ interval: 4, stopOnHover: true }"
            [imageSize]="{ width: '100%', height: '350px' }"
            #nav
          ></ng-image-slider>
        </div>
      </ng-template>
    </div>
  
  
  </div>
  