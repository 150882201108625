<section class="appointment-area ptb-100" style="background-image: none; background-color: #f4f9fd;" >
    <div class="container">
        <div class="section-title">
			<span>Send Message</span>
			<h2>Drop us message for any query</h2>
			<p>If you have an idea, we would love to hear about it.</p>
		</div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="bizQueryForm" (ngSubmit)="sendBizQuery()">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" formControlName="firstName" class="form-control" required data-error="First name" placeholder="First Name*">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" formControlName="lastName" class="form-control" required data-error="Last name" placeholder="Last Name">
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="email"  formControlName="email"  class="form-control" placeholder="Email*">
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <input type="text" formControlName="telephone" class="form-control" placeholder="Phone*">
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <textarea name="message" class="form-control"  formControlName="message" id="message" cols="30" rows="6" placeholder="Your Message*"></textarea>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <button type="submit" class="btn btn-primary">Send Message 
                                                    <i class="flaticon-right-chevron"></i>
                                                </button>
                                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
        </div>
        
                </div>
            </section>