
<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>
<app-background></app-background>
<section class="about-area">
    <div class="container-fluid ">
        <div class="row ">
            <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="about-content" style="max-width: max-content;">
                        <span>About Us</span>
                        <h2>Short Story About {{merchantService.merchant.name}}</h2>
                        <p itemprop="description" style="text-align: justify;">{{merchantService.merchant.shortBio}}</p>
                        
                        <a routerLink="/about" title="About us" itemprop="url" class="btn btn-primary">Learn More 
                            <i class="flaticon-right-chevron"></i>
                        </a>

						<br/>	<br/>	<br/>	
                    </div>
                </div>
                <div class="col-lg-2"></div>
        </div>
    </div>
</section>
<section class="services-area ptb-100 bg-f4f9fd">
	<div class="container">
		<div class="section-title">
			<h1>Our Services</h1>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let svcItem of service.serviceList">
				<div class="single-product-box">
					<div class="product-image">
						<a href="/{{svcItem.slug}}" title="{{svcItem.name}}" itemprop="url">
							<img *ngIf="!svcItem.primaryImage" itemprop="image" alt="{{svcItem.name}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png">
							<img *ngIf="svcItem.primaryImage" src="{{svcItem.primaryImage.small}}" alt="{{svcItem.name}}"/>
						</a>
					</div>
					<div class="product-content">
								<h3>
									<a href="/{{svcItem.slug}}" title="{{svcItem.name}}" itemprop="url">
                                        {{svcItem.name}}
                                    </a>
								</h3>
								<div class="rating">
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
					</div>
				</div>
			</div>
        </div>
    </div>
</section>

<section class="shop-area ptb-100">
	<div class="container">
        <div class="section-title">
			<h1>Our Doctors</h1>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let pdItem of productService.sideBarProducts">
				<div class="single-product-box">
					<div class="product-image">
						<a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
							<img itemprop="image" *ngIf="!pdItem.primaryImage" src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/doctors/doctors-1.jpg" alt="{{pdItem.name}}">
								<img *ngIf="pdItem.primaryImage" src="{{pdItem.primaryImage.small}}" alt="{{pdItem.name}}">
								</a>
							</div>
							<div class="product-content">
                                
                                    <h3 itemprop="name">
                                        <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
                                            {{pdItem.name}}
                                        </a>
                                    </h3>
								
								<span itemprop="category"> {{pdItem.category}}
                                   
                                </span>
							</div>
						</div>
					</div>

				</div>
			</div>
</section>

        <app-bizquery></app-bizquery>

		<section class="blog-area ptb-100">
			<div class="container">
			   <div class="section-title">
				  <span>News &amp; Blog</span>
				  <h2>Latest Updates from Our Blog</h2>
				</div>
			   <div class="row">
				  <div class="col-lg-4 col-md-6" *ngFor="let bgItem of blogService.Posts">
					 <div class="single-blog-post">
						<div class="post-image">
						   <a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">
							   <img *ngIf="bgItem.imageModel" itemprop="image" src="{{bgItem.imageModel.small}}" alt="{{bgItem.title}}">
							   <img *ngIf="!bgItem.imageModel" itemprop="image" alt="{{bgItem.title}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png">
							</a>
						</div>
						<div class="post-content">
						   <div class="post-meta">
							  <ul>
								 <li>By: <a routerLink="/">Admin</a></li>
								 <li>{{bgItem.createdOn | dateFormat}}</li>
							  </ul>
						   </div>
						   <h3><a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">{{bgItem.title}}</a></h3>
						   <p innerHtml="{{bgItem.description | slice:0:60 }}..."></p>
						   <br/>
    						<a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">Read More</a>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
		 </section>

        <app-footer></app-footer>