import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { cartModel } from 'src/app/core/models/cart/cart.models';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { product } from 'src/app/core/models/products/product.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  

  loaderEnabled = true;
  imageData: ImageSliderModel[] = [];
  constructor(private route: ActivatedRoute, 
    public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    private themeService: ThemeChangerService,
    private router: Router,
    public productService: ProductService) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  async ngOnInit(): Promise<void> {
    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));
    
    
    await this.productService.getProductDetails(0, 3);
    let productInfo = await this.productService.getProductInfoPromise(index);
    this.productService.productInfo = productInfo;
    const tempArray: ImageSliderModel[] = [];
    for (let index = 0; index < productInfo.secondaryImageModel.length; index++) {
      const element = productInfo.secondaryImageModel[index];
     tempArray.push({
        alt: productInfo.name,
        image: element.large,
        thumbImage: element.large,
        title: ''
      })
    }
    this.imageData = [...tempArray];
    await this.themeService.changeThemeColors();
    this.setSEOData();
    this.loaderEnabled = false;
  }


  extractContent(html: string) {

    return new DOMParser().parseFromString(html, "text/html") . 
        documentElement . textContent;
  
  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   
    let title = this.productService.productInfo.name;
    let description = this.extractContent(this.productService?.productInfo?.description);
    if(this.productService.productInfo.shortBio)
    {
      title = title + ", "+ this.productService.productInfo.shortBio;
    }

    let aboutDescription = `${title}, ${description}, ${title} in ${merchantDetails.locality},${title} in ${merchantDetails.city},  Doctors of ${merchantDetails.name}, Doctors of ${merchantDetails.name} in ${merchantDetails.locality}, Doctors of ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/albums/1"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }



  

}
