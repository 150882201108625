<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>
<section class="page-title-area page-title-bg1" style="background-image:none; background-color: #f4f9fd">
    <div class="d-table">
    <div class="d-table-cell">
    <div class="container">
    <div class="page-title-content">
    <h2>Services</h2>
    <ul>
   
     <li><a title="{{merchantService.merchant.name}}" itemprop="url" routerlink="/">Home</a></li>
    <li>Services</li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </section>
<section class="services-area ptb-100 bg-f4f9fd" style="background-color: #fff;">
	<div class="container">
		<div class="section-title">
			<h1>Our Services</h1>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let svcItem of categoryService.serviceList">
				<div class="single-product-box">
					<div class="product-image">
						<a href="/{{svcItem.slug}}" title="{{svcItem.name}}" itemprop="url">
                            <img *ngIf="!svcItem.primaryImage" itemprop="image" alt="{{svcItem.name}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png">
							<img *ngIf="svcItem.primaryImage" src="{{svcItem.primaryImage.small}}" alt="{{svcItem.name}}"/>
						</a>
					</div>
					<div class="product-content">
								<h3>
									<a href="/{{svcItem.slug}}" title="{{svcItem.name}}" itemprop="url">
                                        {{svcItem.name}}
                                    </a>
								</h3>
								<div class="rating">
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
					</div>
				</div>
			</div>
        </div>
    </div>

   
</section>

<app-footer></app-footer>