<app-header></app-header>

<section class="page-title-area page-title-bg1">
    <div class="d-table">
    <div class="d-table-cell">
    <div class="container">
    <div class="page-title-content">
    <h2>Frequently Asked Questions</h2>
    <ul>
    <li><a href="index.html">Home</a></li>
    <li>FAQ</li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    
    <section class="faq-area ptb-100">
    <div class="container-fluid p-0">
    <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
    <div class="faq-image">
    <img src="https://themedata.s3.ap-south-1.amazonaws.com/healthcare/assets/img/faq-img1.jpg" alt="image">
    </div>
    </div>
    <div class="col-lg-6 col-md-12 p-0">
    <div class="faq-accordion">
    <span class="sub-title">Frequently Asked Questions</span>
    <h2>Get Every Single Answers There if you want</h2>
    <ul class="accordion">
     <li class="accordion-item">
    <a class="accordion-title active" href="javascript:void(0)">
    <i class="fas fa-plus"></i>
    My physician is a faculty member—what does that mean?
    </a>
    <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
    </li>
    <li class="accordion-item">
    <a class="accordion-title" href="javascript:void(0)">
    <i class="fas fa-plus"></i>
    What is an academic medical center?
    </a>
    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
    </li>
    <li class="accordion-item">
    <a class="accordion-title" href="javascript:void(0)">
    <i class="fas fa-plus"></i>
    What doctor will I see?
    </a>
    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
    </li>
    <li class="accordion-item">
    <a class="accordion-title" href="javascript:void(0)">
    <i class="fas fa-plus"></i>
    What is a medical student?
    </a>
    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
    </li>
    <li class="accordion-item">
    <a class="accordion-title" href="javascript:void(0)">
    <i class="fas fa-plus"></i>
    How are residents supervised?
    </a>
    <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
    </li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    
    <section class="faq-contact-area ptb-100 pt-0">
    <div class="container">
    <div class="section-title">
    <span>Message Us</span>
    <h2>Do You Have Any Questions</h2>
    <p>If you have an idea, we would love to hear about it.</p>
    </div>
    <div class="faq-contact-form">
    <form id="contactForm">
    <div class="row">
     <div class="col-lg-6 col-md-6">
    <div class="form-group">
    <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
    <div class="help-block with-errors"></div>
    </div>
    </div>
    <div class="col-lg-6 col-md-6">
    <div class="form-group">
    <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
    <div class="help-block with-errors"></div>
    </div>
    </div>
    <div class="col-lg-6 col-md-6">
    <div class="form-group">
    <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone">
    <div class="help-block with-errors"></div>
    </div>
    </div>
    <div class="col-lg-6 col-md-6">
    <div class="form-group">
    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
    <div class="help-block with-errors"></div>
    </div>
    </div>
    <div class="col-lg-12 col-md-12">
    <div class="form-group">
    <textarea name="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your message" placeholder="Your Message"></textarea>
    <div class="help-block with-errors"></div>
    </div>
    </div>
    <div class="col-lg-12 col-md-12">
    <button type="submit" class="btn btn-primary">Send Message <i class="flaticon-right-chevron"></i></button>
    <div id="msgSubmit" class="h3 text-center hidden"></div>
    <div class="clearfix"></div>
    </div>
    </div>
    </form>
    </div>
    </div>
    <div class="bg-map"><img src="https://themedata.s3.ap-south-1.amazonaws.com/healthcare/assets/img/bg-map.png" alt="image"></div>
    </section>
    <app-footer></app-footer>
    
