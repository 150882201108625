<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>
<section class="page-title-area page-title-bg5" style="background-image:none; background-color: #f4f9fd">
    <div class="d-table">
    <div class="d-table-cell">
    <div class="container">
    <div class="page-title-content">
    <h2>Blog</h2>
    <ul>
    <li><a title="Home" routerlink="/">Home</a></li>
    <li>Blog</li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    
    <section class="blog-area ptb-100">
    <div class="container">
    <div class="row">
    <div class="col-lg-4 col-md-6" *ngFor="let bgItem of blogService.Posts">
    <div class="single-blog-post">
    <div class="post-image">
        <img itemprop="image" *ngIf="bgItem.imageModel" src="{{bgItem.imageModel.small}}" alt="{{bgItem.title}}">
        <img *ngIf="!bgItem.imageModel" itemprop="image" alt="{{bgItem.title}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png">
        <a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">
           
           
        </a>
    
    </div>
    <div class="post-content">
    <div class="post-meta">
    <ul>
    <li>By: <a href="#">{{bgItem.username }} </a></li>
    <li itemprop="validFrom">{{bgItem.createdOn | date:'dd/MMM/yyyy'}}</li>
     </ul>
    </div>
    <h3><a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">{{bgItem.title}}</a></h3>
    <p innerHtml="{{bgItem.description | slice:0:60 }}..."></p>
    <br/>
    <a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">Read More</a>
    </div>
    </div>
    </div>
   
  
    
        <div class="col-lg-12 col-md-12">
            <div class="pagination-area">
                <a itemprop="url" title="Previous page"  routerLink="{{blogService.prevLink}}" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
               
                <a itemprop="url" title="Next page" routerLink="{{blogService.nextLink}}" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                
            </div>
        </div>
   
   
    </div>
    </div>
    </section>
    <app-footer></app-footer>
