import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ThemeChangerService {

  jQuery: any;
  colors: Colors;
  constructor(private httpClient: HttpClient, private cookieService: CookieService) {
    const windowObj = window as any;
    this.jQuery = windowObj.$;
  }
  hideBody() {
    this.jQuery('body').addClass("hidden");
  }
  showBody() {
    this.jQuery('body').removeClass("hidden")
  }
  setColors(colors: Colors): void {
    this.colors = colors;
    this.cookieService.set('colors', JSON.stringify(this.colors));
  }

  checkCookie(): void {
    let colosFromCookies = this.cookieService.get('colors');
    if (colosFromCookies) {
      colosFromCookies = JSON.parse(colosFromCookies);
      this.setColors(colosFromCookies as unknown as Colors);
    }
  }
  async changeThemeColors(): Promise<Colors> {
    this.hideBody();
    let usernameDetails = this.cookieService.get("username");
    this.checkCookie();
    const url = `https://api.ewns.in/api/Merchants/properties/username/${usernameDetails}`;
    if (this.colors) {
      if (this.colors.primaryColor) {
        setTimeout(() => {
          this.setThemePrimaryColor();
        }, 500);
      }
      else { 
        this.showBody();
      }
      return this.colors;
    }
    else {
      const data = await this.httpClient.get<Colors>(url).toPromise();
      this.setColors(data);
      data.primaryColor ? this.setThemePrimaryColor() : this.showBody();
      return this.promiseHelper(data);
    }
  }
  setThemePrimaryColor(): void {
    const pColor = this.colors.primaryColor;
    const secondaryColor = this.colors.secondaryColor ? this.colors.secondaryColor : "#f4f9fd";
    

    this.jQuery("a").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#121521");
    });

    this.jQuery(".single-footer-widget .links-list li a").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#ffffff");
    });

    this.jQuery(".btn-primary").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#ffffff");
    });

    this.jQuery(".products-details-tab .tabs li.current a").css("background", pColor).mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#ffffff");
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#ffffff").css("background", pColor);
    });

    

    this.jQuery(".comments-area .comment-respond .form-submit input").css("background", pColor).mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", "black");
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#ffffff");
    });

    
     
    this.jQuery(".single-blog-post .post-content .post-meta ul li a").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor);
    });

    this.jQuery(".widget-area .widget .widget-title").css("color", pColor);
    this.jQuery(".widget-area .widget .widget-title::before").css("background", pColor);

    this.jQuery(".widget-area .widget_search form button").css("background-color", pColor).mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor).css("background-color", "#def5ee");
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#ffffff").css("background-color", pColor);
    });
    
   
    this.jQuery(".single-footer-widget .social li a").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor).css("background-color", "#def5ee");
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#ffffff").css("background-color", "#3a3c45");
    });

    this.jQuery(".widget-area .tagcloud a").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor).css("background-color", "#def5ee");
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#7d7d7d").css("background-color", "#fff");
    });

    

    

    this.jQuery(".pagination-area .page-numbers").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#fff").css("background-color", pColor).css("box-shadow", "0 2px 10px 0 "+  pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", "black").css("background-color", "#ffffff").css("box-shadow", "0 2px 10px 0 #fffff");
    });

    const socialHeaderSelector = this.jQuery(".header-right-content .top-header-social li a");
    socialHeaderSelector.css("color", pColor).css("background-color", "#def5ee").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#fff").css("background-color", pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor).css("background-color", "#def5ee");
    });

    this.jQuery(".blog-details-desc .article-footer .article-share .social li a ").css("color", pColor).css("background-color", "#def5ee").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#fff").css("background-color", pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor).css("background-color", "#def5ee");
    });

    
    this.jQuery(".header-contact-info li i").css("color", pColor).css("background-color", "#def5ee").mouseover((e: any) => {
      this.jQuery(e.currentTarget).css("color", "#fff").css("background-color", pColor);
    }).mouseout((e: any) => {
      this.jQuery(e.currentTarget).css("color", pColor).css("background-color", "#def5ee");
    });

  
    this.jQuery('.about-content span').css("color", pColor);
    this.jQuery('.btn-primary').css("background", pColor);
    this.jQuery('.btn-primary i').css("background", pColor);
    this.jQuery('.section-title span').css("color", pColor);
    this.jQuery('.newsletter-form button').css("background", pColor);
    this.jQuery('.newsletter-form button i').css("background", pColor);
    this.jQuery('#wa-widget-send-button').css("background", pColor);
    

    this.jQuery(".single-blog-post .post-content .post-meta ul li a").css("color",pColor);
    this.jQuery(".fovia-nav .navbar .navbar-nav .nav-item .dropdown-menu").css("border-color",pColor)


    this.jQuery(".page-title-content ul li").css("color", pColor);

    this.jQuery(".widget-area .widget_categories ul li::before", pColor);

    this.showBody();
  }

  promiseHelper(data: Colors): Promise<Colors> {
    return new Promise((res, rej) => res(data));
  }
}

export interface Colors {
  primaryColor: string,
  secondaryColor: string;
  tertiaryColor: string;
}