<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>

<section class="page-title-area page-title-bg1" style="background-image:none; background-color: #f4f9fd">
    <div class="d-table">
    <div class="d-table-cell">
    <div class="container">
    <div class="page-title-content">
    <h2>About Us</h2>
    <ul>
    <li><a itemprop="url" title="{{merchantService.merchant.name}}" routerlink="/">Home</a></li>
    <li>About Us</li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    <section class="about-area">
        <div class="container-fluid ">
            <div class="row ">
                <div class="col-lg-2"></div>
                    <div class="col-lg-8">
                        <div class="about-content" style="max-width: max-content;">
                            <span>About Us</span>
                            <p itemprop="description" style="text-align: justify;" [innerHTML]="merchantService.merchant.description"></p>
                            
                           
                        </div>
                    </div>
                    <div class="col-lg-2"></div>
            </div>
        </div>
    </section>
    
    
    <section class="shop-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h1>Our Doctors</h1>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let pdItem of productService.sideBarProducts">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
                                <img itemprop="image" *ngIf="!pdItem.primaryImage" src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/doctors/doctors-1.jpg" alt="{{pdItem.name}}">
                                    <img *ngIf="pdItem.primaryImage" src="{{pdItem.primaryImage.medium}}" alt="{{pdItem.name}}">
                                    </a>
                                </div>
                                <div class="product-content">
                                    
                                        <h3 itemprop="name">
                                            <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
                                                {{pdItem.name}}
                                            </a>
                                        </h3>
                                    
                                    <span itemprop="category"> {{pdItem.category}}
                                       
                                    </span>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
    </section>
 
    
   
    <app-footer></app-footer>
