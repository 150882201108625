<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>
<section class="page-title-area page-title-bg1" style="background-image:none; background-color: #f4f9fd">
    <div class="d-table">
    <div class="d-table-cell">
    <div class="container">
    <div class="page-title-content">
    <h2>{{categoryName}}</h2>
    <ul>
   
     <li><a title="{{merchantService.merchant.name}}" itemprop="url" routerlink="/">Home</a></li>
    <li>{{categoryName}}</li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    
    <section class="shop-area ptb-100">
    <div class="container">
    
    <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let pdItem of categoryService.productList">
    <div class="single-product-box">
    <div class="product-image">
        <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
            <img itemprop="image" *ngIf="!pdItem.primaryImage" src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/doctors/doctors-1.jpg" alt="{{pdItem.name}}">
            <img *ngIf="pdItem.primaryImage" src="{{pdItem.primaryImage.small}}" alt="{{pdItem.name}}">
    </a>
   
    </div>
    
    <div class="product-content">
        <h3 itemprop="name">  <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
            {{pdItem.name}}
        </a></h3>
          <span itemprop="category">{{pdItem.category}}</span>
       </div>
    </div>
    </div>
  

    
    </div>
    </div>
    </section>
    <app-footer></app-footer>
