<app-preloader *ngIf="loaderEnabled"></app-preloader>
<app-header></app-header>
<section class="product-details-area ptb-100">
    <div class="container">
       <div class="row align-items-center">
        <div class="col-lg-2"></div>
          <div class="col-lg-8 col-md-12">
             <div class="product-details-image">
                <app-service-carousel [imageData]="imageData"></app-service-carousel>	
                <img *ngIf="!productService?.serviceInfo?.primaryImage" itemprop="image" alt="{{productService?.serviceInfo?.name}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/large/5fff3d958d5dbc10304de1bb.png">
             </div>
          </div>
          <div class="col-lg-2"></div>
          <br/>
        </div>

        <div class="row align-items-center">
        
          <div class="col-lg-12 col-md-12">
             <div class="product-details-desc">
                <br/><br/>
                <h3 itemprop="name">{{productService?.serviceInfo?.name}}</h3>
                <div class="price">
                   <span class="new-price">{{productService?.serviceInfo?.category}}</span>
                </div>
                <p itemprop="description">{{productService?.serviceInfo?.shortBio}}</p>
                
                
             </div>
          </div>
          <div class="col-lg-12 col-md-12">
             <div class="tab products-details-tab">
                <div class="row">
                   <div class="col-lg-12 col-md-12">
                      <ul class="tabs active">
                         <li class="current">
                            <a href="#">
                               <div class="dot"></div>
                               Description
                            </a>
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-12 col-md-12">
                      <div class="tab_content">
                         <div class="tabs_item">
                            <div class="products-details-tab-content">
                               <p itemprop="description" style="text-align: justify;" [innerHTML]="productService?.serviceInfo?.description | keepHtml"></p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div class="related-products">
       <div class="container">
          <div class="section-title">
             <h2>Our Services</h2>
            
          </div>
          <div class="row">
             <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let pdItem of productService?.serviceList">
                <div class="single-product-box">
                   <div class="product-image">
                      <a routerLink="/{{pdItem.slug}}" title="{{pdItem.name}}" itemprop="url">
                            <img *ngIf="pdItem.primaryImage" itemprop="image" src="{{pdItem.primaryImage.small}}" alt="{{pdItem.name}}">
                            <img *ngIf="!pdItem.primaryImage" itemprop="image" alt="{{pdItem.name}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/large/5fff3d958d5dbc10304de1bb.png">
                      </a>
                   </div>
                   <div class="product-content">
                      <h3>
                         <a routerLink="/{{pdItem.slug}}" title="{{pdItem.name}}" itemprop="url">{{pdItem.name}}</a>
                      </h3>
                      <div class="price">
                         <span class="new">{{pdItem.category}}</span>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>

 <app-footer></app-footer>