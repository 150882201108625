import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import { SubscriberService } from 'src/app/core/services/subscriber/subscriber.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  footerYear: string | any;
  public subscriberForm: FormGroup;
  constructor(public merchantService: MerchantService,
    public productService: ProductService,
    public service: ServiceService,
     private subscriberService: SubscriberService,) { 
    this.subscriberForm = new FormGroup({
      email: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.footerYear = new Date().getFullYear();
    this.service.getServicesForFooter(0,6);
  }

  subcribeUser(): void {
    const { email } = this.subscriberForm.value;
    this.subscriberService.subscribeUser(email);
  }
}
