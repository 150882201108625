<section class="footer-area">
	<div class="container">
		<div class="subscribe-area" >
			<div class="row align-items-center">
				<div class="col-lg-6 col-md-12">
					<div class="newsletter-content">
						<h2>Sign up for our Newsletter</h2>
						<p>Subscribe to the newsletter for all the latest updates</p>
					</div>
				</div>
				<div class="col-lg-6 col-md-12">
					<form class="newsletter-form" [formGroup]="subscriberForm" (ngSubmit)="subcribeUser()">
						<input formControlName="email" type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
							<button type="submit">Subscribe 
								<i class="fas fa-paper-plane"></i>
							</button>
						</form>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="single-footer-widget">
						<div class="logo">
                            <h1 style="color: #fff;">Reach us at</h1>
							<p>{{merchantService.merchant.address}} {{merchantService.merchant.locality}} {{merchantService.merchant.city}} {{merchantService.merchant.country}} {{merchantService.merchant.pincode}}</p>
                            <p >
                                <a  style="color: #fff;"title="Call us" href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}">{{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                            </p>
                            <p >
                                <a  style="color: #fff;"title="Email us" href="mailto:{{merchantService.merchant.email}}">{{merchantService.merchant.email}}</a>
                            </p>
                            
							</div>
							<ul class="social">
								<li>
									<a href="{{merchantService.merchant.socialLinks.fbUrl}}" *ngIf="merchantService.merchant.socialLinks">
										<i class="fab fa-facebook-f"></i>
									</a>
                                    <a href="#" *ngIf="!merchantService.merchant.socialLinks">
										<i class="fab fa-facebook-f"></i>
									</a>
								</li>
								<li>
									<a href="#" *ngIf="!merchantService.merchant.socialLinks">
										<i class="fab fa-twitter"></i>
									</a>
                                    <a href="{{merchantService.merchant.socialLinks.twitterUsername}}" *ngIf="merchantService.merchant.socialLinks">
										<i class="fab fa-twitter"></i>
									</a>
								</li>
								<li>
									<a href="#" *ngIf="!merchantService.merchant.socialLinks">
										<i class="fab fa-linkedin-in"></i>
									</a>
                                    <a href="{{merchantService.merchant.socialLinks.linkedIn}}" *ngIf="merchantService.merchant.socialLinks">
										<i class="fab fa-linkedin-in"></i>
									</a>
								</li>
								<li>
									<a href="#" *ngIf="!merchantService.merchant.socialLinks">
										<i class="fab fa-instagram"></i>
									</a>
                                    <a href="{{merchantService.merchant.socialLinks.instagram}}" *ngIf="merchantService.merchant.socialLinks">
										<i class="fab fa-instagram"></i>
									</a>
								</li>
                                <li>
									<a href="#" *ngIf="!merchantService.merchant.socialLinks">
										<i class="fab fa-pinterest"></i>
									</a>
                                    <a href="{{merchantService.merchant.socialLinks.pinterest}}" *ngIf="merchantService.merchant.socialLinks">
										<i class="fab fa-pinterest"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
                    <div class="col-lg-3 col-md-6 col-sm-6">
						<div class="single-footer-widget pl-5">
							<h3>Links</h3>
							<ul class="links-list">
								<li>
									<a title="{{merchantService.merchant.name}}" itemprop="url" routerLink="/">Home</a>
								</li>
								<li>
									<a title="Doctors at {{merchantService.merchant.name}}" itemprop="url"  routerLink="/doctors/1">Our Doctors</a>
								</li>
								<li>
									<a title="Blog of {{merchantService.merchant.name}}" itemprop="url"  routerLink="/blog/1">Blog</a>
								</li>
								<li>
									<a title="Contact {{merchantService.merchant.name}}" itemprop="url"  routerLink="/contact">Contact Us</a>
								</li>
								<li>
									<a title="About {{merchantService.merchant.name}}" itemprop="url" routerLink="/about">About Us</a>
								</li>
								<li>
									<a title="Sitemap of {{merchantService.merchant.name}}" itemprop="url"  routerLink="sitemap.xml">Sitemap</a>
								</li>
							</ul>
						</div>
					</div>
				    <div class="col-lg-3 col-md-6 col-sm-6">
						<div class="single-footer-widget pl-5">
							<h3>Our Services</h3>
							<ul class="links-list">
								<li *ngFor="let svcItem of service.footerServiceList">
									<a title="{{svcItem.name}}" routerLink="/{{svcItem.slug}}" itemprop="url">{{svcItem.name}}</a>
								</li>
								
							</ul>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6">
						<div class="single-footer-widget">
							<h3>Opening Hours</h3>
							<ul class="opening-hours">
								<li>Monday
									<span *ngIf="merchantService.merchant.bizHours.monday">{{merchantService.merchant.bizHours.monday}}</span>
									<span *ngIf="!merchantService.merchant.bizHours.monday">Off</span>
								</li>
                                <li>Tuesday
									<span *ngIf="merchantService.merchant.bizHours.tuesday">{{merchantService.merchant.bizHours.tuesday}}</span>
									<span *ngIf="!merchantService.merchant.bizHours.tuesday">Off</span>
								</li>
								<li>Wednesday 
									<span *ngIf="merchantService.merchant.bizHours.wednesday">{{merchantService.merchant.bizHours.wednesday}}</span>
									<span *ngIf="!merchantService.merchant.bizHours.wednesday">Off</span>
								</li>
								<li>Thursday 
									<span *ngIf="merchantService.merchant.bizHours.thursday">{{merchantService.merchant.bizHours.thursday}}</span>
									<span *ngIf="!merchantService.merchant.bizHours.thursday">Off</span>
								</li>
								<li>Friday 
									<span *ngIf="merchantService.merchant.bizHours.friday">{{merchantService.merchant.bizHours.friday}}</span>
									<span *ngIf="!merchantService.merchant.bizHours.friday">Off</span>
								</li>
								<li>Saturday 
									<span *ngIf="merchantService.merchant.bizHours.saturday">{{merchantService.merchant.bizHours.saturday}}</span>
									<span *ngIf="!merchantService.merchant.bizHours.saturday">Off</span>
								</li>
                                <li>Sunday 
									<span *ngIf="merchantService.merchant.bizHours.sunday">{{merchantService.merchant.bizHours.sunday}}</span>
									<span *ngIf="!merchantService.merchant.bizHours.sunday">Off</span>
								</li>
							</ul>
						</div>
					</div>
				</div>

                <div class="copyright-area">
                    <p><i class="far fa-copyright"></i> {{footerYear}} Powered By <a routerLink="/">{{merchantService.merchant.name}}</a></p>
                </div>
			</div>
		</section>
		<div class="go-top">
			<i class="fas fa-chevron-up"></i>
		</div>

