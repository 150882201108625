
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './Components/home/home.component';
//import { ContactComponent } from "./Components/contact/ContactComponent";
import { BlogComponent } from './Components/blog/blog.component';
import { AboutComponent } from './Components/about/about.component';
import { FaqComponent } from './Components/faq/faq.component';
import { ProductsComponent } from './Components/products/products.component';
import {AuthGuardService} from 'src/app/core/services/authguard/auth-guard.service';
import { ContactComponent } from './Components/contact/contact.component';
import { ErrorComponent } from './Components/error/error.component';
import { BlogDetailsComponent } from './Components/blog/blog-details/blog-details.component';
import { ProductDetailsComponent } from './Components/products/product-details/product-details.component';
import { ProductCategoryComponent } from './Components/products/product-category/product-category.component';
import { ServicesComponent } from './Components/services/services.component';
import { ServiceDetailsComponent } from './Components/services/service-details/service-details.component';
import { ServiceCategoriesComponent } from './Components/services/service-categories/service-categories.component';
import { AlbumsComponent } from './Components/albums/albums.component';
import { AlbumDetailsComponent } from './Components/albums/album-details/album-details.component';
import { TestimonialsComponent } from './Components/testimonials/testimonials.component';

//const routes: Routes = [];
const routes: Routes = [{
  path: '',
  canActivateChild: [AuthGuardService], 
  children:
    [
       { path: '', component: HomeComponent },
       { path: 'contact', component: ContactComponent },
       {path: 'blog/:index', component: BlogComponent},
       {path: 'blog/:name/:id', component: BlogDetailsComponent},

       { path: 'about', component: AboutComponent },
       { path: 'faq', component: FaqComponent },

       {path: 'doctors/:index', component: ProductsComponent},
       {path: 'doctors/:name/:index', component: ProductDetailsComponent},
       {path: 'departments/doctors/:name/:index', component: ProductCategoryComponent},

       {path: 'services/:index', component: ServicesComponent},
       {path: 'services/:name/:index', component: ServiceDetailsComponent},
       {path: 'departments/services/:name/:index', component: ServiceCategoriesComponent},

       {path: 'albums/:index', component: AlbumsComponent},
       {path: 'albums/:name/:index', component: AlbumDetailsComponent},

       { path: 'testimonials', component: TestimonialsComponent },

      { path: '404', component: ErrorComponent }
    ]
},
{
  path: '**', redirectTo: '/404'
}];
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
