import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  loaderEnabled = true;
  constructor(private route: ActivatedRoute, public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    private themeService: ThemeChangerService,
    public service: ServiceService,private router: Router) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  async ngOnInit(): Promise<void> {
    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));
    
    await this.service.getServices(index-1, 9);
    await this.themeService.changeThemeColors();
    this.setSEOData();
    this.loaderEnabled = false;
  }


  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `Services of ${merchantDetails.name}, Services of ${merchantDetails.name} in ${merchantDetails.locality}, Services of ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/services/1"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
