import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { AlbumService } from 'src/app/core/services/albums/album.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';

@Component({
  selector: 'app-album-details',
  templateUrl: './album-details.component.html',
  styleUrls: ['./album-details.component.css']
})
export class AlbumDetailsComponent implements OnInit {

  loaderEnabled = true;
  constructor(private route: ActivatedRoute,
    public merchantService: MerchantService,
    private metaService: Meta,
    private titleService: Title,
    private router: Router,
    private themeService: ThemeChangerService,
    public albumService: AlbumService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  imageData: ImageSliderModel[] = [];
  private subscription: Subscription = new Subscription();

  async ngOnInit(): Promise<void> {
    const routeParams = this.route.snapshot.paramMap;
    const index = String(routeParams.get('index'));

    this.albumService.getAlbumDetails(index);

    
   


    this.subscription = this.albumService.albInfoUpdated.subscribe(async (data: any) => {
      const tempArray: ImageSliderModel[] = [];
      for (let index = 0; index < data.images.length; index++) {
        const element = data.images[index];
        tempArray.push({
          alt: element.caption,
          thumbImage: element.url,
          image: element.url,
          title: element.caption
        })
      }
      this.imageData = [...tempArray];
      await this.themeService.changeThemeColors();
      this.loaderEnabled = false;
    });

    this.setSEOData();
    
 
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;


    let aboutDescription = `${this.albumService.albumInfo.name} , Albums of ${merchantDetails.name}, Albums of ${merchantDetails.name} in ${merchantDetails.locality}, Albums of  ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({ name: 'name', content: aboutDescription });
    this.metaService.addTag({ name: 'description', content: aboutDescription });
    this.metaService.addTag({ name: 'image', content: merchantDetails.logoImage.small })
    this.metaService.addTag({ name: 'logo', content: merchantDetails.logoImage.small })
    this.metaService.addTag({ name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList })


    this.metaService.addTag({ name: 'twitter:card', content: 'product' });
    this.metaService.addTag({ name: 'twitter:title', content: aboutDescription });
    this.metaService.addTag({ name: 'twitter:description', content: aboutDescription });
    this.metaService.addTag({ name: 'twitter:image', content: merchantDetails.logoImage.small })


    let albumTitle = this.albumService.albumInfo.name.replace(" ", "-");
    //OG GRAPH DATA
    this.metaService.addTag({ name: 'og:type', content: 'article' });
    this.metaService.addTag({ name: 'og:site_name', content: merchantDetails.name });
    this.metaService.addTag({ name: 'og:url', content: merchantDetails.url + "/albums/" + albumTitle + "/" + this.albumService.albumInfo._id });
    this.metaService.addTag({ name: 'og:description', content: aboutDescription });
    this.metaService.addTag({ name: 'og:image', content: merchantDetails.logoImage.small })
  }

}
