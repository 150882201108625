<div *ngIf="imageData.length > 0">
    <div *ngIf="!isMobile$; else mobile">
      <ng-image-slider
      [images]="imageData"
      [infinite]="true"
      [autoSlide]="1"
      [autoSlide]="{ interval: 4, stopOnHover: true }"
      [imageSize]=" { width: '100%', height: '70%' }"
      #nav
    ></ng-image-slider>
    </div>
    <ng-template #mobile>
      <div class="section about-us-area t-center">
        <ng-image-slider
          [images]="imageData"
          [infinite]="true"
          [autoSlide]="1"
          [autoSlide]="{ interval: 4, stopOnHover: true }"
          [imageSize]=" { width: '100%', height: '350px' }"
          #nav
        ></ng-image-slider>
      </div>

    </ng-template>
  </div>
  