<section class="error-area">
    <div class="d-table">
       <div class="d-table-cell">
          <div class="container">
             <div class="error-content">
                <img src="https://themedata.s3.ap-south-1.amazonaws.com/healthcare/assets/img/404.png" alt="error">
                <h3>Page Not Found</h3>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <a routerLink="/" class="btn btn-primary">Go to Home <i class="flaticon-right-chevron"></i></a>
             </div>
          </div>
       </div>
    </div>
 </section>