import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.css']
})
export class BackgroundComponent implements OnInit {

  sliderImages: ImageSliderModel[] = [];
  sliderImage: any;
  constructor(public merchantService: MerchantService) { }

  ngOnInit(): void {
    this.getBackgroundImages();
  }


  getBackgroundImages(): void {
    const title = this.merchantService.merchant.name;
    this.merchantService.getBackgroundImage().pipe(
      take(1)
    ).subscribe((backgroundImagesList: any) => {
      const newArray: ImageSliderModel[] = [];
      if (backgroundImagesList.length > 0) {
        backgroundImagesList.forEach((element: any) => {
          
          const sliderImage: ImageSliderModel = {
            alt: title,
            image: element.imageModel.large,
            thumbImage: element.imageModel.large,
            title: ''
          };
          newArray.push(sliderImage);
          this.sliderImage = sliderImage;
        });
        this.sliderImages = [...newArray];
      }
      else {
        this.sliderImages = [...newArray];
      }
    });
  }

}
