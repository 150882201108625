<app-preloader *ngIf="loaderEnabled"></app-preloader>

<app-header></app-header>

<section class="blog-area ptb-100" *ngIf="albumService.albums.length > 0">
    <div class="container">
       <div class="section-title">
          <span>Image Gallery</span>
          <h2>Here are some of our Albums</h2>
        </div>
       <div class="row">
          <div class="col-lg-4 col-md-6" *ngFor="let albumItem of albumService.albums">
             <div class="single-blog-post">
                <div class="post-image">
                   <a routerLink="/albums/{{albumItem.name}}/{{albumItem._id}}" itemprop="url">
                       <img *ngIf="albumItem.images.length > 0 " itemprop="image" src="{{albumItem.images[0].image.small}}" alt="{{albumItem.name}}">
                       <img *ngIf="albumItem.images.length == 0 " itemprop="image" alt="{{albumItem.name}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png">
                    </a>
                </div>
                <div class="post-content">
                   
                   <h3><a routerLink="/albums/{{albumItem.name}}/{{albumItem._id}}" itemprop="url">{{albumItem.name}}</a></h3>
                  </div>
             </div>
          </div>
       </div>
    </div>
 </section>

 <section class="blog-area ptb-100" *ngIf="albumService.albums.length == 0">
    <div class="container">
       <div class="section-title">
          <span>Image Gallery</span>
          <h2>Here are some of our Albums</h2>
        </div>
       <div class="row">
          <div class="col-lg-12 col-md-6">
             <h4> No Albums added yet, please subscribe to our newsletter to get updates on our albums</h4>
          </div>
       </div>
    </div>
 </section>

<app-footer></app-footer>