import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { BlogService } from 'src/app/core/services/blogs/blog.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import { ThemeChangerService } from 'src/app/core/services/services/theme-changer.service';
import { SubscriberService } from 'src/app/core/services/subscriber/subscriber.service';
import { TestimonialsService } from 'src/app/core/services/testimony/testimonials.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loaderEnabled = true;
  sliderImages: ImageSliderModel[] = [];
  sliderImage: any;
  constructor(public merchantService: MerchantService,
    public productService: ProductService,
    public testimonialService: TestimonialsService,
    public service: ServiceService,
    public blogService: BlogService,
    private metaService: Meta,
    private titleService: Title,
    private themeService: ThemeChangerService
  ) {

  }

  async ngOnInit(): Promise<void> {
   
    this.setSEOData();
    await this.productService.getProductsForSidebar();
    await this.service.getServices(0, 3);
    await this.blogService.getPosts(0, 3);
   
    await this.getBackgroundImages();
    await this.themeService.changeThemeColors();
    this.loaderEnabled = false;
  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;


    let aboutDescription = `${merchantDetails.name}, ${merchantDetails.name} in ${merchantDetails.locality}, ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({ name: 'name', content: aboutDescription });
    this.metaService.addTag({ name: 'description', content: aboutDescription });
    this.metaService.addTag({ name: 'image', content: merchantDetails.logoImage.small })
    this.metaService.addTag({ name: 'logo', content: merchantDetails.logoImage.small })
    this.metaService.addTag({ name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList })


    this.metaService.addTag({ name: 'twitter:card', content: 'product' });
    this.metaService.addTag({ name: 'twitter:title', content: aboutDescription });
    this.metaService.addTag({ name: 'twitter:description', content: aboutDescription });
    this.metaService.addTag({ name: 'twitter:image', content: merchantDetails.logoImage.small })


    //OG GRAPH DATA
    this.metaService.addTag({ name: 'og:type', content: 'article' });
    this.metaService.addTag({ name: 'og:site_name', content: merchantDetails.name });
    this.metaService.addTag({ name: 'og:url', content: merchantDetails.url + "/about" });
    this.metaService.addTag({ name: 'og:description', content: aboutDescription });
    this.metaService.addTag({ name: 'og:image', content: merchantDetails.logoImage.small })
  }

  async getBackgroundImages(): Promise<void> {
    const title = this.merchantService.merchant.name;
    let bgImageList = await this.merchantService.getBackgroundImagePromise();

    if(bgImageList != null && bgImageList.length > 0)
    {
      const newArray: ImageSliderModel[] = [];
      if (bgImageList.length > 0) {
        bgImageList.forEach((element: any) => {
          const sliderImage: ImageSliderModel = {
            alt: title,
            image: element.imageModel.large,
            thumbImage: element.imageModel.large,
            title: ''
          };
          newArray.push(sliderImage);
        });
        this.sliderImages = [...newArray];
      }
      else {
        this.sliderImages = [...newArray];
      }
    }
  }

}
