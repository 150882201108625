import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { cartModel } from '../../models/cart/cart.models';
import { product } from '../../models/products/product.models';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  totalCount: number | any;
  cartArray: Array<cartModel> =[];
  products : Array<product> | any;
  productInfo : product | any;
  count: number | any;
  nextLink: string | any;
  prevLink: string | any;
  sideBarProducts : Array<product> | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  async getProductDetails(skip: number, limit: number){
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const productsListEndPoint =  environment.apiUrl + '/api/Products/'+ username +"/list?skip="+skip+"&limit="+limit;
      this.products = await this.http.get<product[]>(productsListEndPoint).toPromise();
      this.count = this.products.length;
      this.pagination(skip +1);
    }

  }

  getProductInfo(index: number)
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const productDetailsEndPoint =  environment.apiUrl + '/api/Products/'+ username +"/"+ index;
      this.http.get<product[]>(productDetailsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : product |any) => {
        this.productInfo = data;
      });
    }

    
  }


  getTotalProducts()
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const totalProductsEndPoint =  environment.apiUrl + '/api/Products/'+ username +"/count";
      this.http.get<number>(totalProductsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : number |any) => {
        this.totalCount = data;
      });
    }
  }

  async getProductsForSidebar()
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const productsListEndPoint =  environment.apiUrl + '/api/Products/'+ username +"/list?skip=0&limit="+3;
      this.sideBarProducts = await this.http.get<product[]>(productsListEndPoint).toPromise();
    }
  }

  addToCart(cartObject: cartModel){
    let cartsArrayString =  this.cookieService.get("carts");
    if(cartsArrayString && cartsArrayString != undefined)
    {
      this.cartArray = JSON.parse(cartsArrayString);
      if(this.cartArray.find(s=> s.productId == cartObject.productId))
      {
         let existingProduct = this.cartArray.find(s=> s.productId == cartObject.productId);
         let quantity = 0;
         if(existingProduct != null)
         {
           quantity = existingProduct?.quantity;
         }
         cartObject.quantity = cartObject.quantity + quantity;
         this.cartArray.splice(this.cartArray.findIndex(a=> a.productId == cartObject.productId), 1);
      }
    }
   
    this.cartArray.push(cartObject);
    this.cookieService.set("carts" , JSON.stringify(this.cartArray));
    
    alert("Product: " + cartObject.name + "  is added to cart");
  }


  removeProductFromCart(productId: string)
  {
      if(this.cartArray != null)
      {
          let existingProduct = this.cartArray.find(s=> s.productId == productId);
          if(existingProduct != null)
          {
            this.cartArray.splice(this.cartArray.findIndex(a=> a.productId == productId), 1);
          }
      }
      else{
        alert("Cart is empty");
      }
  }


  getProductInfoObserver(index: number): Observable<any> {
    const username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      const productDetailsEndPoint = environment.apiUrl + '/api/Products/' + username + "/" + index;
      return this.http.get<product[]>(productDetailsEndPoint);
     
    }
    return of(null);


  }

  getProductInfoPromise(index: number): Promise<any> {
    let username = this.cookieService.get("username");
    let productDetailsEndPoint = environment.apiUrl + '/api/Products/' + username + "/" + index;
    return this.http.get<product[]>(productDetailsEndPoint).toPromise();

  }



  pagination(index: number){
    this.nextLink = "/doctors/" + index;
    this.prevLink = "/doctors/" + (index-1);
    if(index <= 1)
    {
      this.prevLink = "/doctors/1";
      if(this.count < 9)
      {
        this.nextLink = "/doctors/1";
      }
      else{
        this.nextLink = "/doctors/" + (index+1);
      }
    }
    else
    {
      this.prevLink = "/doctors/" + (index-1);
      if(this.count < 9)
      {
        this.nextLink = "/doctors/" + (index);
      }
      else{
        this.nextLink = "/doctors/" + (index+1);
      }
    }

  }
}
