import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';

@Component({
  selector: 'app-service-carousel',
  templateUrl: './service-carousel.component.html',
  styleUrls: ['./service-carousel.component.css']
})
export class ServiceCarouselComponent implements OnInit {

  isMobile$ = false;
  subscription: Subscription = new Subscription()
  constructor(private cd: ChangeDetectorRef, private breakPoint: BreakpointObserver) {

  }

  @Input()
  imageData: ImageSliderModel[] = [];
  ngOnInit(): void {
    this.subscription = this.breakPoint.observe([Breakpoints.Handset, Breakpoints.HandsetLandscape]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.HandsetPortrait] || state.breakpoints[Breakpoints.HandsetLandscape]) {
        this.isMobile$ = true;
      }
      else {
        this.isMobile$ = false;
      }
    })
    this.cd.detectChanges()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.imageData.firstChange) {
      this.imageData = [...changes.imageData.currentValue]
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
