import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { blogPost } from '../../models/blog/blog.models';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  Posts: blogPost[] | any;
  Article: blogPost | any;
  count: number | any;
  nextLink: string | any;
  prevLink: string | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  async getPosts(skip: number, limit: number) {
    let username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      const blogPostListEndPoint = environment.apiUrl + '/api/blogPosts/list/' + username + "?skip=" + skip + "&limit=" + limit;
      this.Posts = await this.http.get<blogPost[]>(blogPostListEndPoint).toPromise();
      this.count = this.Posts.length;
      this.pagination(skip + 1);
    }


  }

  async getPostInfo(index: number) {
    const username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      const blogPostDetailsEndPoint = environment.apiUrl + '/api/blogPosts/' + username + "/" + index;
      this.Article = await this.http.get<blogPost[]>(blogPostDetailsEndPoint).toPromise();
    }


  }

  pagination(index: number) {
    this.nextLink = "/blog" + index;
    this.prevLink = "/blog" + (index - 1);
    if (index <= 1) {
      this.prevLink = "/blog/1";
      if (this.count < 6) {
        this.nextLink = "/blog/1";
      }
      else {
        this.nextLink = "/blog/" + (index + 1);
      }
    }
    else {
      this.prevLink = "/blog/" + (index - 1);
      if (this.count < 6) {
        this.nextLink = "/blog/" + (index);
      }
      else {
        this.nextLink = "/blog/" + (index + 1);
      }
    }

  }
}
