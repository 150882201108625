<app-preloader *ngIf="loaderEnabled"></app-preloader>

<app-header></app-header>

<section class="services-area ptb-100 bg-f4f9fd" *ngIf="testimonialService.testimonials.length > 0">
	<div class="container">
		<div class="section-title">
			<h1>Testimonials</h1>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let svcItem of testimonialService.testimonials">
				<div class="single-product-box">
					<div class="product-image">
						<img *ngIf="!svcItem.image" itemprop="image" alt="{{svcItem.name}}" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png">
							<img *ngIf="svcItem.image" src="{{svcItem.image.small}}" alt="{{svcItem.name}}"/>
					</div>
					<div class="product-content">
								<h3>
									{{svcItem.title}}
								</h3>
                                <p>By: {{svcItem.name}}</p>
								<div class="rating">
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>

                                <p>{{svcItem.description}}</p>
					</div>
				</div>
			</div>
        </div>
    </div>
</section>

<section class="services-area ptb-100 bg-f4f9fd"  *ngIf="testimonialService.testimonials.length == 0">
	<div class="container">
		<div class="section-title">
			<h1>Testimonials</h1>
		</div>
		<div class="row">
			<h4>No Testimonials added yet, keep watching this space for more updates</h4>
        </div>
    </div>
</section>

<app-footer></app-footer>